import React from "react"

import Layout from "../components/layout"
import Page404 from '../components/page-404/index'
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout page="404">
    <SEO title="联每户" />
    <Page404 />
  </Layout>
)

export default NotFoundPage
