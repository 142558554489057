import React from "react"
import styles from "./index.module.css"
import { Link } from "gatsby"
const Page404 = () => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.left}>
          <img
            src={
              "https://static.lianmeihu.com/files/lmh_dev/img/x-uploader/2021/January/29/723633c29a7744c191bd9af4cf2de486"
            }
            alt=""
            style={{ width: 443, height: "auto" }}
          />
        </div>
        <div className={styles.right}>
          <div className={styles.title}>404</div>
          <p>抱歉您访问的页面不存在</p>
          <Link to="/">返回</Link>
        </div>
      </div>
    </div>
  )
}

export default Page404
